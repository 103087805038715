import React, { useEffect } from 'react';
import moment from 'moment-timezone';
import CssBaseline from '@material-ui/core/CssBaseline';
import ToastContainer from 'components/widgets/layout/ToastContainer';
import { ThemeProvider } from '@material-ui/core/styles';
import { useGameSocket, useFanbandSocket } from 'hooks';
import { BrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  BANNER_IMAGE_PATH,
  TITLE,
  SITE_URL,
  DESCRIPTION,
} from 'utils/constants';

import theme from 'theme';
import Routes from 'routes';
import { TIME_ZONE } from 'config';

function App() {
  useGameSocket();
  useFanbandSocket();

  useEffect(() => {
    moment.tz.setDefault(TIME_ZONE); 
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>{TITLE}</title>
        <meta name="theme-color" content={theme.custom.palette.white} />

        {/* Open Graph / Facebook */}
        <meta property="og:url" content={SITE_URL} />
        <meta property="og:title" content={TITLE} />
        <meta property="og:description" content={DESCRIPTION} />
        <meta property="og:image" content={BANNER_IMAGE_PATH} />

        {/* Twitter */}
        <meta property="twitter:url" content={SITE_URL} />
        <meta property="twitter:title" content={TITLE} />
        <meta property="twitter:description" content={DESCRIPTION} />
        <meta property="twitter:image" content={BANNER_IMAGE_PATH} />
        <meta
          name="msapplication-TileColor"
          content={theme.custom.palette.orange}
        />
      </Helmet>
      <CssBaseline />
      <ToastContainer />
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
