import { ALERT_PROTO_TYPES, LINKS } from 'utils/constants';
import { useAlertsSent, usePathIndicator } from 'hooks';

const PromoAlertsSent = () => {
  usePathIndicator([LINKS.promo, LINKS.promoSent]);

  return useAlertsSent({
    type: ALERT_PROTO_TYPES.promo, 
    title: 'Promo alerts sent',
  });
};

export default PromoAlertsSent;
