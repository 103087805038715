import { memo, useCallback, useState, useMemo, useEffect } from 'react';
import { Button, IconButton, Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add as AddIcon, Close as CloseIcon } from '@material-ui/icons';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import _ from 'lodash';
import {
  POLL_TITLE_VALID,
  DEFAULT_ALERT_PARAMS,
  ALERT_FORM_MODES,
  POLL_QUESTION_VALID,
  MAX_RESPONSE_NUMBER,
  RESPONSE_VALID,
} from 'utils/constants';
import {
  AlertField,
  FanbandTerminal,
  FormButton,
  ExpImageField,
  ExpTextField,
  QuickPollScreen,
} from 'components/elements';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  input: {
    marginBottom: theme.spacing(4),
  },
  anotherResponseButton: {
    backgroundColor: theme.palette.info.main,
    fontSize: 12,
    height: 35,
    marginBottom: 30,
    '&:hover': {
      backgroundColor: theme.palette.info.dark,
    },
  },
}));

const QuickPollForm = ({
  onSubmit,
  onDelete,
  mode = ALERT_FORM_MODES.creating,
  defaultValues = null,
  deleting = false,
  isSuccess = false,
}) => {
  const classes = useStyles();
  const [image, setImage] = useState(
    defaultValues ? { url: defaultValues.imageUrl } : null
  );
  const [responseCount, setResponseCount] = useState(
    defaultValues?.surveyResponses?.length > 2 
      ? defaultValues.surveyResponses.length 
      : 2
  );

  const resolver = useMemo(() => {
    const validations = {
      title: POLL_TITLE_VALID,
      body: POLL_QUESTION_VALID,
    };

    for (let i = 0; i < responseCount; i ++) {
      validations[`response${i}`] = RESPONSE_VALID;
    }

    return yupResolver(yup.object().shape(validations));
  }, [responseCount]);

  const [alertParams, setAlertParmas] = useState(
    defaultValues
      ? _.pick(defaultValues, Object.keys(DEFAULT_ALERT_PARAMS()))
      : DEFAULT_ALERT_PARAMS()
  );

  const formDefaultValues = useMemo(() => {
    const output = {
      title: '',
      body: '',
      response0: '',
      response1: '',
      ..._.pick(defaultValues, ['title', 'body']),
    };

    defaultValues?.surveyResponses.forEach((res, idx) => {
      output[`response${idx}`] = res.response ?? "";
    })

    return output;
  }, [defaultValues]);

  const { control, handleSubmit, errors, reset, watch, setValue, getValues } = useForm({
    resolver,
    mode: 'onChange',
    defaultValues: formDefaultValues,
  });

  const addResponse = useCallback(() => {
    setResponseCount(responseCount + 1);
  }, [responseCount]);

  const removeResponse = useCallback((idx) => {
    for (let i = idx; i < responseCount - 1; i ++) {
      setValue(`response${i}`, getValues(`response${i + 1}`));
    }
    setResponseCount(responseCount - 1);
  }, [getValues, responseCount, setValue]);

  const resetParams = (params) => {
    setAlertParmas(params || DEFAULT_ALERT_PARAMS());
  };

  const handleParamsChange = useCallback(({ target: { name, value } }) => {
    setAlertParmas((params) => ({ ...params, [name]: value }));
  }, []);

  const values = watch();
  const questionTxt = useMemo(() => {
    return values.body;
  }, [values]);
  const responses = useMemo(() => {
    return Array.from({ length: responseCount }).map((_, idx) => values[`response${idx}`]?.replace(',', ':&'));
  }, [responseCount, values]);

  const submitHandler = (data) => {
    const responses = Array.from({ length: responseCount }).map((_, idx) => data[`response${idx}`].replace(',', ':&'));
    onSubmit({
      ..._.pick(data, ['title', 'body']),
      ...alertParams,
      file: image?.file,
      responses,
    });
  };

  const resetForm = useCallback(() => {
    setImage(null);
    setResponseCount(2);
    resetParams();
    reset();
  }, [reset]);

  useEffect(() => {
    if (
      isSuccess &&
      (mode === ALERT_FORM_MODES.creating ||
      mode === ALERT_FORM_MODES.saving)
    ) {
      resetForm();
    }
  }, [isSuccess, mode, resetForm])

  return (
    <form
      noValidate
      className={classes.root}
      onSubmit={handleSubmit(submitHandler)}
    >
      <Grid container>
        <Grid item lg={9} xs={12} container spacing={2}>
          <Grid item xs={12}>
            <Controller
              as={<ExpTextField />}
              name="title"
              label="Quick Poll title"
              error={errors.title?.message}
              control={control}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              as={<ExpTextField />}
              name="body"
              label="Quick Poll question"
              error={errors.body?.message}
              control={control}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            {Array.from({ length: responseCount }).map((_, idx) => (
              <Box key={idx} display="flex" mb={2}>
                <Controller
                  defaultValue={""}
                  as={<ExpTextField />}
                  name={`response${idx}`}
                  label={`Response #${idx + 1}`}
                  error={errors[`response${idx}`]?.message}
                  control={control}
                  fullWidth  
                  rules={{ required: true }}
                />
                {responseCount > 2 && (
                  <Box display="flex" alignItems="center">
                    <IconButton
                      onClick={() =>
                        removeResponse(idx)
                      }
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                )}
              </Box>
            ))}
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<AddIcon />}
              className={classes.anotherResponseButton}
              fullWidth={false}
              onClick={addResponse}
              disabled={responseCount >= MAX_RESPONSE_NUMBER}
            >
              Add another response
            </Button>
          </Grid>
          <Grid item lg={6} xs={12}>
            <ExpImageField
              label="Image"
              image={image}
              onChange={setImage}
              width="100%"
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <AlertField
              label="Alert Parameters"
              value={alertParams}
              onChange={handleParamsChange}
              onReset={resetParams}
              width="90%"
              mt={3}
              terminalScreen={
                <QuickPollScreen text={questionTxt} responses={responses} />
              }
            />
          </Grid>
        </Grid>
        <Grid container item lg={3} xs={12} justifyContent="center">
          <FanbandTerminal params={alertParams} disabledAnimation>
            <QuickPollScreen text={questionTxt} responses={responses} />
          </FanbandTerminal>
        </Grid>
      </Grid>
      <Box mt={2} display="flex">
        <FormButton type="submit">
          {mode === ALERT_FORM_MODES.updating ||
          mode === ALERT_FORM_MODES.saving
            ? 'Save'
            : 'Send'}
        </FormButton>
        {deleting && (
          <Box ml={2} width="100%">
            <FormButton color="secondary" onClick={onDelete}>
              Delete
            </FormButton>
          </Box>
        )}
      </Box>
    </form>
  );
};

export default memo(QuickPollForm);
