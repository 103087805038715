import axios from 'services/axios';

const readUsers = async (params) => {
  if (params?.phoneNumber) {
    return await axios.get('/users/for-phone', { params });
  }
  return await axios.get('/users', params);
};

// const getUser = async (_id) => {
//   return await axios.get(`/api/users/${_id}`);
// };

// const addUser = async (params) => {
//   return await axios.post('/api/users', params);
// };

// const editUser = async (params) => {
//   return await axios.put('/api/users', params);
// };

// const removeUser = async (_id) => {
//   return await axios.delete('/api/users', { params: { _id } });
// };

export { readUsers };
