import * as yup from 'yup';
import { calcStringWidthForFirmware } from 'utils/helpers';

export const NAME_VALID = yup.string().required('Please enter name.');

export const EMAIL_VALID = yup
  .string()
  .email('Please enter a valid email address.')
  .required('Please enter email address.');

export const PASSWORD_VALID = yup
  .string()
  .required('Please enter in Password.')
  .min(6, 'Passwords need to be at least 6 characters.');

export const CONFIRM_PASSWORD_VALID = yup
  .string()
  .required('Please enter in Password.')
  .oneOf([yup.ref('password'), null], 'Passwords needs to match.');

export const TITLE_VALID = yup
  .string()
  .trim('This field cannot include leading and trailing spaces')
  .required('Please enter in this field.')
  .matches(/^[A-Za-z0-9' ?!]*$/, 'Please do not input special character')

export const STRING_VALID = yup
  .string()
  .trim('This field cannot include leading and trailing spaces')
  .required('Please enter in this field.')
  .matches(/^[A-Za-z0-9' ?!]*$/, 'Please do not input special character');

  export const RESPONSE_VALID = yup
  .string()
  .trim('This field cannot include leading and trailing spaces')
  .required('Please enter in this field.')
  .matches(/^[A-Za-z0-9' ]*$/, 'Please do not input special character')
  .max(9, 'Response must not exceed 9 characters.');
  // .test("length", "Text length is too long", (value) => calcStringWidthForFirmware(value) < MAX_FIRMWARE_STRING_WIDTH)

export const MAX_FIRMWARE_STRING_WIDTH = 75;

export const MAX_RESPONSE_NUMBER = 4;

export const NEWS_TITLE_VALID = TITLE_VALID.max(37, 'Title must not exceed 37 characters.');

export const PROMO_TITLE_VALID = TITLE_VALID.max(37, 'Title must not exceed 37 characters.');

export const POLL_TITLE_VALID = TITLE_VALID.max(31, 'Title must not exceed 31 characters.');

export const POLL_QUESTION_VALID = STRING_VALID.max(31, 'Question must not exceed 31 characters.');

