import { memo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createAlert, setCreateAlertStatus } from 'redux/actions';
import { getEnglishDateWithTime } from 'utils/helpers';
import { ALERT_PROTO_TYPES } from 'utils/constants';
import {
  LeftContainer,
  FHCard,
  FHCardContent,
  FHCardHeader,
} from 'components/styled-components';
import { QuickPollForm } from 'components/widgets';

const QuickPoll = () => {
  const dispatch = useDispatch();
  const createAlertStatus = useSelector((state) => state.notifications.createAlertStatus);

  const handleCreate = (data) => {
    dispatch(createAlert(ALERT_PROTO_TYPES.survey, data));
  };

  useEffect(() => {
    dispatch(setCreateAlertStatus("idle"));
  }, [createAlertStatus, dispatch])

  return (
    <LeftContainer maxWidth="md">
      <FHCard>
        <FHCardHeader
          title="Create Quick Poll Alert"
          subheader={getEnglishDateWithTime(new Date())}
        />
        <FHCardContent>
          <QuickPollForm onSubmit={handleCreate} isSuccess={createAlertStatus === "success"} />
        </FHCardContent>
      </FHCard>
    </LeftContainer>
  );
};

export default memo(QuickPoll);
