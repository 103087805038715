import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAsyncAction } from 'hooks';
import { getUserInfo } from 'redux/actions';
import { LINKS } from 'utils/constants';

import HomePage from 'pages';
import NewsPage from 'pages/news';
import NewsAlertsSent from 'pages/news/news-sent';
import GamedayThemePage from 'pages/gameday';
import GamedayAlertsSent from 'pages/gameday/gameday-sent';
import PromoPage from 'pages/promo';
import PromoAlertsSent from 'pages/promo/promo-sent';
import SavedPage from 'pages/saved';
import QuickPollPage from 'pages/quickpoll';
import QuickPollAlertsSent from 'pages/quickpoll/quickpoll-sent';
import QuickPollAlertSamplePage from 'pages/quickpoll/quickpoll-sent/id';
import SchedulePage from 'pages/schedule';
import ScheduledSentPage from 'pages/schedule/schedule-sent';
import EditScheduledAlertPage from 'pages/schedule/id';
import ScorePage from 'pages/score';
import ScoreSentPage from 'pages/score/score-sent';
import StatsPage from 'pages/stats';
import FanbandsPage from 'pages/stats/fanbands';
import TicketsPage from 'pages/tickets';
import ModifyTicketPage from 'pages/tickets/ticketId';
import SignInPage from 'pages/auth/sign-in';
import ForgotPasswordPage from 'pages/auth/forgot-password';
import ResetPasswordPage from 'pages/auth/reset-password';
import SavedAlertsPage from 'pages/saved/view-all';
import EditSavedAlertPage from 'pages/saved/id';
import AccelerometerPage from 'pages/accelerometer';

const PAGES = [
  {
    ...LINKS.signIn,
    element: <SignInPage />,
  },
  {
    ...LINKS.forgotPassword,
    element: <ForgotPasswordPage />,
  },
  {
    ...LINKS.resetPassword,
    element: <ResetPasswordPage />,
  },
  {
    ...LINKS.home,
    element: <HomePage />,
  },
  {
    ...LINKS.gameday,
    element: <GamedayThemePage />,
  },
  {
    ...LINKS.gamedaySent,
    element: <GamedayAlertsSent />,
  },

  {
    ...LINKS.news,
    element: <NewsPage />,
  },
  {
    ...LINKS.newsSent,
    element: <NewsAlertsSent />,
  },
  {
    ...LINKS.promo,
    element: <PromoPage />,
  },
  {
    ...LINKS.promoSent,
    element: <PromoAlertsSent />,
  },
  {
    ...LINKS.quickPoll,
    element: <QuickPollPage />,
  },
  {
    ...LINKS.quickPollSent,
    element: <QuickPollAlertsSent />,
  },
  {
    ...LINKS.quickPollSample,
    element: <QuickPollAlertSamplePage />,
  },
  {
    ...LINKS.saved,
    element: <SavedPage />,
  },

  {
    ...LINKS.savedAll,
    element: <SavedAlertsPage />,
  },
  {
    ...LINKS.savedEdit,
    element: <EditSavedAlertPage />,
  },
  {
    ...LINKS.schedule,
    element: <SchedulePage />,
  },
  {
    ...LINKS.scheduleSent,
    element: <ScheduledSentPage />,
  },
  {
    ...LINKS.scheduledEdit,
    element: <EditScheduledAlertPage />,
  },
  {
    ...LINKS.score,
    element: <ScorePage />,
  },
  {
    ...LINKS.scoreSent,
    element: <ScoreSentPage />,
  },
  {
    ...LINKS.stats,
    element: <StatsPage />,
  },
  {
    ...LINKS.statsFanbands,
    element: <FanbandsPage />,
  },
  {
    ...LINKS.tickets,
    element: <TicketsPage />,
  },
  {
    ...LINKS.ticketModify,
    element: <ModifyTicketPage />,
  },
  {
    ...LINKS.accelerometer,
    element: <AccelerometerPage />,
  },
];

export default function Routes() {
  const navigate = useNavigate();
  const { isAuthenticated, isInitialized } = useSelector((state) => state.auth);
  const { pathname } = useLocation();

  const routes = useMemo(() => {
    if (!isInitialized) {
      return [];
    }
    if (!isAuthenticated) {
      return PAGES.filter((r) => !r.protected);
    }
    return PAGES;
  }, [isAuthenticated, isInitialized]);

  useEffect(() => {
    if (!isInitialized) {
      return;
    }

    const protectedPages = Object.values(LINKS)
      .filter((p) => p.protected && p.path !== LINKS.home.path)
      .map((p) => p.path);
    const isProtectedPage =
      pathname === LINKS.home.path ||
      protectedPages.some((p) => pathname.startsWith(p.replace(/\:[^]*/, '')));

    if (isProtectedPage && !isAuthenticated) {
      navigate(LINKS.signIn.path);
    }
  }, [navigate, pathname, isInitialized, isAuthenticated]);

  useAsyncAction(getUserInfo());

  return useRoutes(routes);
}
