import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  setGames,
  setSelectedGame,
  setClosestUpcomingGame,
} from 'redux/actions/games';
import { useSocket } from 'hooks';
import { ALERT_MIXED_TYPES, WS_EVENTS } from 'utils/constants';
import { addAlert, updateAlert } from 'redux/actions';

const useGameSocket = () => {
  const dispatch = useDispatch();
  const { results = [], select = {} } = useSelector((state) => state.games);

  const liveGameHandler = useCallback(
    (game) => {
      try {
        const newGames = results.map((item) =>
          item.id === game.id ? game : item
        );
        dispatch(setGames(newGames));

        if (select.id === game.id) {
          dispatch(setSelectedGame(game));
        }

        dispatch(setClosestUpcomingGame(game));
      } catch (error) {
        console.log(error);
      }
    },
    [results, select, dispatch]
  );

  const handleUpcomingScheduledAlert = useCallback(
    (alert) => {
      dispatch(addAlert(alert, ALERT_MIXED_TYPES.scheduled));
    },
    [dispatch]
  );

  const handleSentScheduledAlert = useCallback(
    (alert) => {
      dispatch(updateAlert(alert, ALERT_MIXED_TYPES.scheduled));
    },
    [dispatch]
  );

  useSocket(WS_EVENTS.LIVE_GAME_UPDATE, liveGameHandler);
  useSocket(
    WS_EVENTS.UPCOMING_SCHEDULED_NOTIFICATION,
    handleUpcomingScheduledAlert
  );
  useSocket(WS_EVENTS.SENT_SCHEDULED_NOTIFICATION, handleSentScheduledAlert);
};

export default useGameSocket;
