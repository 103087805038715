import { Tab, Tabs } from '@material-ui/core';
import { TabPanel, TabContext } from '@material-ui/lab';
import { useState } from 'react';
import { FanbandTable } from 'components/widgets';
import {
  FHCard,
  FHCardHeader,
  FHCardContent,
} from 'components/styled-components';
import { FANBAND_LABELS, FANBAND_TYPES } from 'utils/constants';

const Fanbands = () => {
  const [tab, setTab] = useState(FANBAND_TYPES.provisioned);

  return (
    <FHCard>
      <FHCardHeader
        title={
          <Tabs value={tab} onChange={(e, newValue) => setTab(newValue)}>
            <Tab
              value={FANBAND_TYPES.provisioned}
              label={FANBAND_LABELS.provisioned}
            />
            <Tab
              value={FANBAND_TYPES.nonProvisioned}
              label={FANBAND_LABELS.nonProvisioned}
            />
            <Tab
              value={FANBAND_TYPES.all}
              label={FANBAND_LABELS.all}
            />
          </Tabs>
        }
      />
      <FHCardContent>
        <TabContext value={tab}>
          <TabPanel value={FANBAND_TYPES.provisioned}>
            <FanbandTable pageType={FANBAND_TYPES.provisioned} />
          </TabPanel>
          <TabPanel value={FANBAND_TYPES.nonProvisioned}>
            <FanbandTable pageType={FANBAND_TYPES.nonProvisioned} />
          </TabPanel>
          <TabPanel value={FANBAND_TYPES.all}>
            <FanbandTable pageType={FANBAND_TYPES.all} />
          </TabPanel>
        </TabContext>
      </FHCardContent>
    </FHCard>
  );
};

export default Fanbands;
