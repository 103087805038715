import { Layout, CurrentFanbandStats } from 'components/widgets';
import { Tickets } from 'components/containers';
import { usePathIndicator } from 'hooks';
import { LINKS } from 'utils/constants';

export default function TicketsPage() {
  usePathIndicator(LINKS.tickets);

  return (
    <Layout sidebar={<CurrentFanbandStats />}>
      <Tickets />
    </Layout>
  );
}
