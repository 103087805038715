import { Layout, CurrentFanbandStats } from 'components/widgets';
import { QuickPollAlertSample } from 'components/containers';
import { LINKS } from 'utils/constants';
import { usePathIndicator } from 'hooks';
import { useParams } from 'react-router-dom';

const QuickPollAlertSamplePage = () => {
  const { id } = useParams();

  usePathIndicator([LINKS.quickPoll, LINKS.quickPollSent]);

  return (
    <Layout sidebar={<CurrentFanbandStats />}>
      <QuickPollAlertSample alertId={id} />
    </Layout>
  );
};

export default QuickPollAlertSamplePage;
