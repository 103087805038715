import * as alertsAPI from 'services/api-alerts';
import * as TYPES from 'redux/actionTypes';
import { ALERT_MIXED_TYPES, ALERT_PROTO_TYPES } from 'utils/constants';
import { setLoadingStatus } from './auxiliary';
import { setResponseSuccess, setResponseError } from '.';

export const getAlerts = (params) => async (dispatch, getState) => {
  try {
    // if game selected, get alerts for that game only
    if (params.gameId) {
      const games = getState().games.results;
      let startDate, endDate;
      for (let i = 0; i < games.length; i++) {
        if (games[i].id === params.gameId) {
          startDate = games[i].startTime;
          endDate = i > 0 ? games[i - 1].startTime : null;
          break;
        }
      }
      delete params.gameId;
      if (startDate && endDate) {
        params = {
          ...params,
          startDate,
          endDate,
        }
      }
    }

    // default take to 10
    if (!params.take) {
      params.take = 10;
    }

    const { results = [], total = 0 } = await alertsAPI.getAlerts(params);
    dispatch({
      type: TYPES.SET_ALERTS,
      payload: {
        results,
        total,
        type: params.type,
      },
    });
  } catch (e) {
    dispatch(setResponseError(e.response));
  }
};

export const getAlertById = (id) => async (dispatch) => {
  try {
    const result = await alertsAPI.getSingleAlert(id);
    console.log(result);
    // dispatch({
    //   type: TYPES.SET_ALERTS,
    //   payload: {
    //     results,
    //     total,
    //     type: params.type,
    //   },
    // });
  } catch (e) {
    dispatch(setResponseError(e.response));
  }
};

export const setAlerts =
  (type = '', results = []) =>
  async (dispatch, getState) => {
    try {
      const { notifications } = getState();
      const { total = 0 } = notifications[type];

      await dispatch({
        type: TYPES.SET_ALERTS,
        payload: {
          results,
          total,
          type,
        },
      });
    } catch (error) {
      dispatch(setResponseError(error));
    }
  };

export const addAlert = (alert, type) => async (dispatch) => {
  dispatch({
    type: TYPES.ADD_ALERT,
    payload: {
      alert,
      type
    },
  });
};

export const updateAlert = (alert, type) => async (dispatch) => {
  dispatch({
    type: TYPES.UPDATE_ALERT,
    payload: {
      alert,
      type
    },
  });
}


// create alert
export const createAlert =
  (type, data, scheduledTime = null) =>
  async (dispatch) => {
    dispatch(setLoadingStatus(true));

    try {
      let response;

      if (scheduledTime) {
        response = await alertsAPI.createScheduledNotification({
          ...data,
          type,
          scheduledTime,
        });
      } else {
        response = await alertsAPI.createNotification({ ...data, type });
      }

      dispatch(
        getAlerts({ 
          type: scheduledTime ? ALERT_MIXED_TYPES.scheduled : type,
        })
      );

      dispatch(setResponseSuccess(response));
      dispatch(setCreateAlertStatus("success"));
    } catch (e) {
      dispatch(setResponseError(e.response));
      dispatch(setCreateAlertStatus("failed"));
    }

    dispatch(setLoadingStatus(false));
  };

export const setCreateAlertStatus = (status) => {
  return {
    type: TYPES.SET_CREATE_ALERT_STATUS,
    payload: status,
  };
};

// calendar
export const setSelectedDate = (date) => ({
  type: TYPES.SELECT_DATE,
  payload: date,
});

export const setAlertToShow = (alert, visibility) => ({
  type: TYPES.SET_ALERT_TO_SHOW,
  payload: { alert, visibility },
});

// saved
export const getSavedAlerts = () => async (dispatch) => {
  dispatch(
    getAlerts({ 
      type: ALERT_MIXED_TYPES.saved,
    })
  );
};

export const insertSavedAlert = (type, data) => async (dispatch) => {
  dispatch(setLoadingStatus(true));

  try {
    const response = await alertsAPI.createSavedAlert({ ...data, type });
    dispatch(setResponseSuccess(response));
    dispatch(setSaveAlertStatus("success"));
    await dispatch(getSavedAlerts());
  } catch (e) {
    dispatch(setResponseError(e.response));
    dispatch(setSaveAlertStatus("failed"));
  }

  dispatch(setLoadingStatus(false));
};

export const modifySavedAlert = (id, params) => async (dispatch) => {
  dispatch(setLoadingStatus(true));

  try {
    const response = await alertsAPI.updateSavedAlert(id, params);
    dispatch(setResponseSuccess(response));
    dispatch(setSaveAlertStatus("success"));
    await dispatch(getSavedAlerts());
  } catch (e) {
    dispatch(setResponseError(e.response));
    dispatch(setSaveAlertStatus("failed"));
  }

  dispatch(setLoadingStatus(false));
};

export const removeSavedAlert = (id) => async (dispatch) => {
  dispatch(setLoadingStatus(true));

  try {
    const response = await alertsAPI.deleteSavedAlert(id);
    await dispatch(getSavedAlerts());
    dispatch(setResponseSuccess(response));
  } catch (e) {
    dispatch(setResponseError(e.response));
  }

  dispatch(setLoadingStatus(false));
};

export const sendSavedAlert = (id) => async (dispatch) => {
  dispatch(setLoadingStatus(true));

  try {
    const response = await alertsAPI.sendSavedAlert(id);
    dispatch(setResponseSuccess(response));
  } catch (e) {
    dispatch(setResponseError(e.response));
  }

  dispatch(setLoadingStatus(false));
};

export const setSaveAlertStatus = (status) => {
  return {
    type: TYPES.SET_SAVE_ALERT_STATUS,
    payload: status,
  };
};

// scheduled
export const getScheduledAlerts = () => async (dispatch) => {
  dispatch(
    getAlerts({ 
      type: ALERT_MIXED_TYPES.scheduled,
    })
  );
};

export const modifyScheduledAlert = (id, params) => async (dispatch) => {
  dispatch(setLoadingStatus(true));

  try {
    const response = await alertsAPI.updateScheduledAlert(id, params);
    dispatch(setResponseSuccess(response));
    dispatch(getScheduledAlerts());
  } catch (e) {
    dispatch(setResponseError(e.response));
  }

  dispatch(setLoadingStatus(false));
};

export const removeScheduledAlert = (id) => async (dispatch) => {
  dispatch(setLoadingStatus(true));

  try {
    const response = await alertsAPI.deleteScheduledAlert(id);
    dispatch(setResponseSuccess(response));
    dispatch(getScheduledAlerts());
  } catch (e) {
    dispatch(setResponseError(e.response));
  }

  dispatch(setLoadingStatus(false));
};

// latest
export const getLatestNotification =
  (type, gameId) => async (dispatch, getState) => {
    try {
      const { results } = getState().games;
      let startDate, endDate;
      for (let i = 0; i < results.length; i++) {
        if (results[i].id === gameId) {
          startDate = results[i].startTime;
          endDate = i > 0 ? results[i - 1].startTime : null;
          break;
        }
      }

      const response = await alertsAPI.getAlerts({
        type,
        startDate,
        endDate,
      });

      await dispatch({
        type: type === ALERT_PROTO_TYPES.survey 
          ? TYPES.SET_LATEST_SURVEY_NOTIFICATION 
          : TYPES.SET_LATEST_NOTIFICATION,
        payload: response.results.length > 0 ? response.results[0] : {},
      });
    } catch (error) {
      dispatch(setResponseError(error));
    }
  };

export const setLatestNotification =
  (type, notification) => async (dispatch) => {
    try {
      let actionType = TYPES.SET_LATEST_NOTIFICATION;
      switch (type) {
        case ALERT_PROTO_TYPES.survey:
          actionType = TYPES.SET_LATEST_SURVEY_NOTIFICATION;
          break;
        default:
          actionType = TYPES.SET_LATEST_NOTIFICATION;
          break;
      }

      await dispatch({
        type: actionType,
        payload: notification,
      });
    } catch (error) {
      dispatch(setResponseError(error));
    }
  };

// gameday presets
export const getGamedayPresets =
  (reloading = false) =>
  async (dispatch, getState) => {
    try {
      const {
        notifications: { gamedayPresets },
      } = getState();

      if (gamedayPresets.length && !reloading) {
        return;
      }

      const { images: presets } = await alertsAPI.readGamedayPresets();
      dispatch(setGamedayPresets(presets));
    } catch (err) {
      dispatch(setResponseError(err));
    }
  };

export const setGamedayPresets = (presets) => ({
  type: TYPES.SET_GAMEDAY_PRESETS,
  payload: presets,
});

export const uploadGamedayPresets = (files) => async (dispatch) => {
  dispatch(setLoadingStatus(true));

  try {
    const res = await alertsAPI.uploadGamedayPresets(files);
    dispatch(setResponseSuccess(res));
    dispatch(getGamedayPresets(true));
  } catch (err) {
    dispatch(setResponseError(err));
  }

  dispatch(setLoadingStatus(false));
};