import { Grid, Divider } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getFanbands } from 'redux/actions';
import { FanbandItem } from 'components/widgets';
import { usePagination } from 'hooks';
import { useCallback } from 'react';

const FanbandTable = ({ pageType }) => {
  const dispatch = useDispatch();
  const { results: fanbands, total } = useSelector((state) => state.main.fanbands);

  const getFanbandsAction = useCallback((params) => {
    dispatch(getFanbands({ ...params, pageType }));
  }, [dispatch, pageType]);
  
  const { paginator } = usePagination({
    count: total,
    action: getFanbandsAction,
  });

  return (
    <>
      <Grid container spacing={3}>
        {fanbands.map((fanband, idx) => (
          <Grid item key={idx} xs={12}>
            <FanbandItem data={fanband} mb={3} />
            {idx < fanbands.length - 1 && <Divider />}
          </Grid>
        ))}
      </Grid>
     {paginator}
    </>
  );
};

export default FanbandTable;
