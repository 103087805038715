import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Link,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { LINKS } from 'utils/constants';
import { useAsyncAction } from 'hooks';
import { getFanbandsStatistics } from 'redux/actions';
import { isEmpty } from 'utils/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 210,
    color: '#000',
  },
  viewListLink: {
    color: theme.palette.info.main,
    fontFamily: theme.custom.fonts.SFUITextRegular,
    fontSize: 12,
  },
  provisioned: {
    fontSize: 12,
    fontFamily: theme.custom.fonts.SFProTextSemibold,
    textTransform: 'uppercase',
  },
  nonProvisioned: {
    fontSize: 12,
    fontFamily: theme.custom.fonts.SFProTextLight,
    textTransform: 'uppercase',
    color: '#999',
  },
  number: {
    fontSize: 40,
    fontFamily: theme.custom.fonts.SFProTextSemibold,
  },
  onlineDesc: {
    color: '#239632',
    fontFamily: theme.custom.fonts.SFProTextMedium,
    fontSize: 14,
  },
  offlineDesc: {
    color: '#d02231',
    fontFamily: theme.custom.fonts.SFProTextMedium,
    fontSize: 14,
  },
  arenaDesc: {
    color: '#000',
    fontFamily: theme.custom.fonts.SFProTextMedium,
    fontSize: 14,
  },
}));

const Fanbands = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    statistics: { offline = 0, online = 0, inArea = 0 },
  } = useSelector((state) => state.main.fanbands);
  const { selectedGame } = useSelector((state) => state.games);

  const viewListLink = (
    <Link
      component="button"
      className={classes.viewListLink}
      underline="hover"
      onClick={() => navigate(LINKS.statsFanbands.path)}
    >
      View list
    </Link>
  );

  useAsyncAction(getFanbandsStatistics(), true);

  const columnSize = useMemo(() => {
    return !isEmpty(selectedGame) ? 4 : 6;
  }, [selectedGame]);

  return (
    <Card className={classes.root}>
      <CardHeader title="Fanbands" subheader={viewListLink} />
      <CardContent>
        <Grid container p={0}>
          <Grid item xs={columnSize}>
            <Typography className={classes.number}>{online}</Typography>
            <Typography className={classes.onlineDesc}>
              Online fanbands
            </Typography>
          </Grid>
          {!isEmpty(selectedGame) &&
            <Grid item xs={columnSize}>
              <Typography className={classes.number}>{inArea}</Typography>
              <Typography className={classes.onlineDesc}>
                In arena
              </Typography>
            </Grid>
          }
          <Grid item xs={columnSize}>
            <Typography className={classes.number}>{offline}</Typography>
            <Typography className={classes.offlineDesc}>
              Offline fanbands
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default memo(Fanbands);
