import axios from 'services/axios';

export const readFanbandsStatistics = async () => {
  return await axios.get('/users/statistics');
};

export const readFanbands = async (params) => {
  if (params?.phoneNumber) {
    return await axios.get('/fanbands/for-phone', { params });
  }
  return await axios.get('/fanbands', { params });
};
