import { ALERT_PROTO_TYPES, LINKS } from 'utils/constants';
import { useAlertsSent, usePathIndicator } from 'hooks';

const NewsAlertsSent = () => {
  usePathIndicator([LINKS.news, LINKS.newsSent]);

  return useAlertsSent({
    type: ALERT_PROTO_TYPES.news, 
    title: 'News alerts sent',
  });
};

export default NewsAlertsSent;
