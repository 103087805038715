import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FHCard,
  FHCardContent,
  FHCardHeader,
} from 'components/styled-components';
import { TicketForm } from 'components/widgets';
import { useAsyncAction } from 'hooks';
import { getTickets, modifyTicket, removeTicket } from 'redux/actions';
import { useNavigate } from 'react-router-dom';
import { LINKS } from 'utils/constants';

const TicketModify = ({ ticketId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const ticketData = useSelector((state) =>
    state.main.tickets.results.find((t) => t.id === ticketId)
  );

  const handleSubmit = async (data) => {
    await dispatch(modifyTicket(ticketId, data));
    navigate(LINKS.tickets.path);
  };

  const handleDelete = async () => {
    await dispatch(removeTicket(ticketId));
    navigate(LINKS.tickets.path);
  };

  useAsyncAction(getTickets(), !ticketData);

  return (
    <FHCard>
      <FHCardHeader title="Modify Ticket" />
      <FHCardContent>
        {ticketData && (
          <TicketForm
            defaultValues={ticketData}
            onSubmit={handleSubmit}
            onDelete={handleDelete}
            updating
          />
        )}
      </FHCardContent>
    </FHCard>
  );
};

export default memo(TicketModify);
