import { Layout, CurrentFanbandStats, AlertsSent } from 'components/widgets';
import { Schedule } from 'components/containers';
import { useAsyncAction, usePathIndicator } from 'hooks';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  ALERT_MIXED_TYPES,
  ALERT_STATUS,
  ALERT_FORM_MODES,
  LINKS,
} from 'utils/constants';

export default function EditScheduledAlertPage() {
  const { id } = useParams();
  const alert = useSelector((state) =>
    state.notifications[ALERT_MIXED_TYPES.scheduled]?.results.find(
      (a) => a.id === id
    )
  );

  usePathIndicator([LINKS.schedule, LINKS.scheduledEdit]);
  // get by id

  return (
    <Layout
      sidebar={
        <>
          <CurrentFanbandStats />
          <AlertsSent
            type={ALERT_MIXED_TYPES.scheduled}
            status={ALERT_STATUS.pending}
            alertLink={LINKS.scheduledEdit.path}
          />
        </>
      }
    >
      {alert && (
        <Schedule
          defaultValues={alert}
          key={id}
          mode={ALERT_FORM_MODES.updating}
        />
      )}
    </Layout>
  );
}
