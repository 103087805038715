import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAlerts, setSelectedDate } from 'redux/actions';
import { Card, CardContent } from '@material-ui/core';
import { CardHeaderButton } from 'components/styled-components';
import { Calendar } from 'components/elements';
import { Add as AddIcon } from '@material-ui/icons';
import { LINKS } from 'utils/constants';
import { useNavigate } from 'react-router-dom';
import { usePathIndicator } from 'hooks';

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedDate, alertStatus } = useSelector(
    (state) => state.notifications
  );

  const handleDateChange = (date) => {
    dispatch(setSelectedDate(date));
  };

  const handleCreateNewsAlert = () => {
    navigate(LINKS.news.path);
  };

  const handlePickerChange = (year, month) => {
    dispatch(
      getAlerts({
        startDate: `${year}-${month}-01`,
        endDate: `${year}-${month}-31`
      })
    );
  };

  usePathIndicator(LINKS.home);

  return (
    <Card>
      <CardContent>
        <Calendar
          value={selectedDate}
          cellData={alertStatus}
          actions={
            <CardHeaderButton
              startIcon={<AddIcon />}
              onClick={handleCreateNewsAlert}
            >
              News Alert
            </CardHeaderButton>
          }
          onChange={handleDateChange}
          onPickerChange={handlePickerChange}
        />
      </CardContent>
    </Card>
  );
};

export default memo(Home);
