import { Card, CardContent, CardHeader, makeStyles } from '@material-ui/core';
import {
  getFanbandsStatistics,
  getGamedayPresets,
  getAlerts,
} from 'redux/actions';
import { Layout, AlertItem, CurrentFanbandStats } from 'components/widgets';
import { Fragment, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAsyncAction, usePagination } from 'hooks';
import { calcPercent } from 'utils/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
  },
  table: {
    tableLayout: 'fixed',
    width: '100%',
    marginTop: -92,
  },
  cell: {
    fontFamily: theme.custom.fonts.SFProDisplayBlackItalic,
    fontSize: 16,
    letterSpacing: '0.48px',
    color: 'black',
    textTransform: 'uppercase',
    textAlign: 'center',
    height: 92,
  },
  divider: {
    height: 0,
    borderBottom: '1px solid #9ea3ba',
  },
}));

const useAlertsSent = ({ type, title, gamedayOnly }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const totalCount = useSelector(
    (state) => state.main.fanbands.statistics.total
  );
  const game = useSelector((state) => state.games.selectedGame);
  const { results: alerts, total } = useSelector((state) => state.notifications[type]);

  const alertsData = useMemo(() => {
    return alerts.map((alert) => ({
      ...alert,
      aggr: {
        sent: {
          count: alert.sent,
          percent: calcPercent(alert.sent, totalCount),
        },
        open: {
          count: alert.opened,
          percent: calcPercent(alert.opened, totalCount),
        },
      },
    }));
  }, [alerts, totalCount]);

  const getAlertsAction = useCallback((params) => {
    dispatch(getAlerts({ 
      ...params, 
      ...gamedayOnly && { gameId: game?.id },
      type,
    }));
  }, [dispatch, game?.id, gamedayOnly, type]);

  const { paginator } = usePagination({
    count: total,
    action: getAlertsAction,
  });

  useAsyncAction(getFanbandsStatistics(), !totalCount);
  useAsyncAction(getGamedayPresets());

  return (
    <Layout sidebar={<CurrentFanbandStats />}>
      <Card className={classes.root}>
        <CardHeader title={title} />
        <CardContent>
          <table className={classes.table}>
            <thead>
              <tr>
                <td style={{ width: 300 }}></td>
                <th className={classes.cell}>sent</th>
                <th className={classes.cell}>open</th>
              </tr>
            </thead>
            <tbody>
              {alertsData.map((alert, idx) => (
                <Fragment key={alert.id}>
                  <tr>
                    <td className={classes.cell}>
                      <AlertItem data={alert} />
                    </td>
                    <td className={classes.cell}>
                      {alert.aggr.sent.count} <br /> {alert.aggr.sent.percent}%
                    </td>
                    <td className={classes.cell}>
                      {alert.aggr.open.count} <br /> {alert.aggr.open.percent}%
                    </td>
                  </tr>
                  {idx < alerts.length - 1 && (
                    <tr>
                      <td className={classes.divider} colSpan={3}></td>
                    </tr>
                  )}
                </Fragment>
              ))}
            </tbody>
          </table>
          {paginator}
        </CardContent>
      </Card>
    </Layout>
  );
};

export default useAlertsSent;
