import { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';

import { getAlerts, getSavedAlerts } from 'redux/actions';
import { Title } from 'components/elements';
import { AlertItem } from 'components/widgets';
import { Button } from '@material-ui/core';
import { useAsyncAction } from 'hooks';
import { ALERT_MIXED_TYPES, LINKS } from 'utils/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  items: {
    overflow: 'auto',
    height: 0,
    marginBottom: 16,
    flexGrow: 1,
  },
  button: {
    borderRadius: theme.spacing(3),
    height: 50,
    marginBottom: 5,
  },
}));

const ActiveSavedAlerts = ({ gamedayOnly }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const game = useSelector((state) => state.games.selectedGame);
  const alerts = useSelector((state) => state.notifications[ALERT_MIXED_TYPES.saved].results);

  const handleViewAll = useCallback(() => {
    navigate(LINKS.savedAll.path);
  }, [navigate]);

  const getAlertsAction = useMemo(() => {
    if (!alerts.length) {
      return getSavedAlerts();
    }
  }, [alerts.length]);

  useAsyncAction(getAlertsAction, getAlertsAction);

  return (
    <div className={classes.root}>
      <Title mb={4}>Active Saved Alerts</Title>

      <div className={classes.items}>
        {alerts.map((item) => (
          <AlertItem key={item.id} data={item} mb={2} action />
        ))}
      </div>

      <Button
        color="primary"
        variant="contained"
        fullWidth
        className={classes.button}
        onClick={handleViewAll}
      >
        View all
      </Button>
    </div>
  );
};

export default memo(ActiveSavedAlerts);
