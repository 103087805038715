import React, { useState, useCallback, useEffect } from 'react';
import {
  TextField,
  InputAdornment,
  Popover,
  Tabs,
  Tab,
  MenuList,
  MenuItem,
  makeStyles,
  InputBase,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { TabContext, TabPanel } from '@material-ui/lab';
import { usePagination } from 'hooks';
import { getFanbands } from 'redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { FANBAND_LABELS, FANBAND_TYPES } from 'utils/constants';

const useStyles = makeStyles((theme) => ({
  item: {
    fontFamily: theme.custom.fonts.SFProTextBold,
    fontSize: 14,
    paddingLeft: 0,
    color: '#000',
    '&>span': {
      fontFamily: theme.custom.fonts.SFProTextRegular,
    },
  },
  hidden: {
    display: 'none',
  },
  tabPanel: {
    paddingTop: 0,
  },
}));

const FanbandTabPanel = ({ value, pageType, onClick }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { results: fanbands, total } = useSelector((state) => state.main.fanbands);

  const [search, setSearch] = useState("");

  const getFanbandsAction = useCallback((params) => {
    dispatch(getFanbands({ ...params, phoneNumber: search, pageType }));
  }, [dispatch, search, pageType]);
  
  const { paginator } = usePagination({
    count: total,
    action: getFanbandsAction,
  });

  return (
    <> 
      {pageType === FANBAND_TYPES.provisioned && (
        <InputBase
          placeholder="Search by phone number"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        /> 
      )}
      <MenuList>
        {fanbands.map((fanband) => {
          const { fanbandUUID, userPhone } = fanband;
          return (
            <MenuItem
              key={fanbandUUID}
              className={classes.item}
              onClick={() => onClick(fanband)}
              selected={fanbandUUID === value}
            >
              <span>&nbsp;{` ∙ Phone: ${userPhone}`}</span>
            </MenuItem>
          );
        })}
        {paginator}
      </MenuList>
    </>
  );
};

const FanbandSelector = React.forwardRef(
  ({ error, label, value, userphone, ...rest }, ref) => {
    const classes = useStyles();
    const popoverActions = React.useRef();
    const [anchorEl, setAnchorEl] = useState(null);
    const [tab, setTab] = useState(FANBAND_TYPES.provisioned);
    const [fanbandLabel, setFanbandLabel] = useState(userphone ?? "");

    const fanbands = useSelector((state) => state.main.fanbands.results);
    
    // update popover position when content size changes
    useEffect(() => {
      popoverActions.current?.updatePosition();
    }, [fanbands.length])

    const handleClick = (fanband) => {
      const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        'value'
      ).set;
      nativeInputValueSetter.call(ref.current, fanband.userId);
      setFanbandLabel(`Phone: ${fanband.userPhone || ''}`);
      const ev1 = new Event('input', { bubbles: true });
      ref.current.dispatchEvent(ev1);
      setAnchorEl(null);
    };

    return (
      <React.Fragment>
        <TextField
          label={label}
          helperText={error}
          error={!!error}
          value={value && fanbandLabel}
          placeholder="Not assigned"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <KeyboardArrowDownIcon />
              </InputAdornment>
            ),
            readOnly: true,
          }}
          InputLabelProps={{ shrink: true }}
          onClick={(e) => setAnchorEl(e.target)}
        />
        <TextField
          inputRef={ref}
          {...rest}
          value={value}
          className={classes.hidden}
        />
        <Popover
          open={Boolean(anchorEl)}
          action={popoverActions}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Tabs value={tab} onChange={(e, newValue) => setTab(newValue)}>
            <Tab
              value={FANBAND_TYPES.provisioned}
              label={FANBAND_LABELS.provisioned}
            />
            <Tab
              value={FANBAND_TYPES.nonProvisioned}
              label={FANBAND_LABELS.nonProvisioned}
            />
            <Tab value={FANBAND_TYPES.all} label={FANBAND_LABELS.all} />
          </Tabs>
          <TabContext value={tab}>
            <TabPanel
              value={FANBAND_TYPES.provisioned}
              className={classes.tabPanel}
            >
              <FanbandTabPanel
                pageType={FANBAND_TYPES.provisioned}
                onClick={handleClick}
                value={value}
              />
            </TabPanel>
            <TabPanel
              value={FANBAND_TYPES.nonProvisioned}
              className={classes.tabPanel}
            >
              <FanbandTabPanel
                pageType={FANBAND_TYPES.nonProvisioned}
                onClick={handleClick}
                value={value}
              />
            </TabPanel>
            <TabPanel value={FANBAND_TYPES.all} className={classes.tabPanel}>
              <FanbandTabPanel
                pageType={FANBAND_TYPES.all}
                onClick={handleClick}
                value={value}
              />
            </TabPanel>
          </TabContext>
        </Popover>
      </React.Fragment>
    );
  }
);

export default FanbandSelector;
