import { Layout, CurrentFanbandStats } from 'components/widgets';
import { Accelerometer } from 'components/containers';
import { usePathIndicator } from 'hooks';
import { LINKS } from 'utils/constants';

const AccelerometerPage = () => {
  usePathIndicator([LINKS.accelerometer]);

  return (
    <Layout sidebar={<CurrentFanbandStats />}>
      <Accelerometer />
    </Layout>
  );
};

export default AccelerometerPage;
