import { Fragment, useCallback } from 'react';
import moment from "moment";
import { Calendar, ExpCheckbox, Title } from 'components/elements';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAlerts,
  setAlertToShow,
  setSelectedDate,
} from 'redux/actions';
import { ALERT_PROTO_TYPES, ALERT_LABELS } from 'utils/constants';

const SelectedGameSidebar = () => {
  const dispatch = useDispatch();
  const {
    selectedDate,
    alertStatus: cellData,
    alertsToShow,
  } = useSelector((state) => state.notifications);

  const handleDateChange = (date) => {
    dispatch(setSelectedDate(date));
  };

  const handleVisibilityChange = (e) => {
    dispatch(setAlertToShow(e.target.name, e.target.checked));
  };

  const handlePickerChange = useCallback((year, month) => {
    if (!(moment(selectedDate).year() === year && moment(selectedDate).month() + 1 === month)) {
      dispatch(setSelectedDate(moment(`${year}-${month}-01`, "YYYY-MM-DD")));
    }
    dispatch(
      getAlerts({
        startDate: `${year}-${month}-01`,
        endDate: `${year}-${month}-31`
      })
    );
  }, [dispatch, selectedDate]);

  return (
    <Fragment>
      <Title mb={3}>Calendar</Title>
      <Calendar
        value={selectedDate}
        minimized={true}
        cellData={cellData}
        onChange={handleDateChange}
        onPickerChange={handlePickerChange}
        mb={4}
      />
      <Title mb={3}>Alerts to show</Title>
      {Object.values(ALERT_PROTO_TYPES).map((type) => (
        <ExpCheckbox
          key={type}
          label={ALERT_LABELS[type]}
          color={type}
          name={type}
          checked={alertsToShow[type]}
          onChange={handleVisibilityChange}
        />
      ))}
    </Fragment>
  );
};

export default SelectedGameSidebar;
