import { memo, useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { createAlert, insertSavedAlert, modifySavedAlert, setCreateAlertStatus, setSaveAlertStatus } from 'redux/actions';
import {
  LeftContainer,
  FHCard,
  FHCardContent,
  FHCardHeader,
} from 'components/styled-components';
import { ExpSelect } from 'components/elements';
import {
  NewsForm,
  PromoForm,
  QuickPollForm,
  ScoreForm,
  GamedayThemeForm,
} from 'components/widgets';
import {
  ALERT_FORM_MODES,
  ALERT_LABELS,
  ALERT_PROTO_TYPES,
} from 'utils/constants';
import { getEnglishDateWithTime } from 'utils/helpers';
import { useNavigate } from 'react-router-dom';

const Saved = ({ defaultValues = null, updating = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [type, setAlertType] = useState(
    defaultValues ? defaultValues.type : ALERT_PROTO_TYPES.news
  );
  const createAlertStatus = useSelector((state) => state.notifications.createAlertStatus);
  const saveAlertStatus = useSelector((state) => state.notifications.saveAlertStatus);

  const handleSubmit = async (data) => {
    if (updating) {
      if (type === ALERT_PROTO_TYPES.promo && data.sending) {
        dispatch(createAlert(type, { ...data, sending: undefined }));
      } else {
        dispatch(modifySavedAlert(defaultValues.id, { ...data, type }));
        navigate(-1);
      }
    } else {
      dispatch(insertSavedAlert(type, data));
    }
  };

  const AlertForm = useMemo(() => {
    switch (type) {
      case ALERT_PROTO_TYPES.news:
        return NewsForm;
      case ALERT_PROTO_TYPES.survey:
        return QuickPollForm;
      case ALERT_PROTO_TYPES.score:
        return ScoreForm;
      case ALERT_PROTO_TYPES.promo:
        return PromoForm;
      case ALERT_PROTO_TYPES.gameday:
        return GamedayThemeForm;
      default:
        return NewsForm;
    }
  }, [type]);

  useEffect(() => {
    dispatch(setCreateAlertStatus("idle"));
  }, [createAlertStatus, dispatch])

  useEffect(() => {
    dispatch(setSaveAlertStatus("idle"));
  }, [saveAlertStatus, dispatch])

  return (
    <LeftContainer maxWidth="md">
      <FHCard>
        <FHCardHeader
          title="Save Alert"
          subheader={getEnglishDateWithTime(new Date())}
        />
        <FHCardContent>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              {updating || (
                <ExpSelect
                  name="type"
                  label="Alert type"
                  items={Object.values(ALERT_PROTO_TYPES).map((type) => ({
                    value: type,
                    label: ALERT_LABELS[type],
                  }))}
                  value={type}
                  onChange={(e) => setAlertType(e.target.value)}
                />
              )}
            </Grid>
          </Grid>
          <AlertForm
            onSubmit={handleSubmit}
            mode={
              updating ? ALERT_FORM_MODES.updating : ALERT_FORM_MODES.saving
            }
            defaultValues={defaultValues}
            isSuccess={createAlertStatus === "success" || saveAlertStatus === "success"}
          />
        </FHCardContent>
      </FHCard>
    </LeftContainer>
  );
};

export default memo(Saved);
