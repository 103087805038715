import { memo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createAlert, setCreateAlertStatus } from 'redux/actions';
import { getEnglishDateWithTime } from 'utils/helpers';
import { GamedayThemeForm } from 'components/widgets';
import { LeftContainer } from 'components/styled-components';
import { FHCard } from 'components/styled-components';
import { FHCardHeader } from 'components/styled-components';
import { FHCardContent } from 'components/styled-components';

import { ALERT_PROTO_TYPES } from 'utils/constants';

const GamedayTheme = () => {
  const dispatch = useDispatch();
  const createAlertStatus = useSelector((state) => state.notifications.createAlertStatus);

  const handleCreate = (data) => {
    dispatch(createAlert(ALERT_PROTO_TYPES.gameday, data));
  };

  useEffect(() => {
    dispatch(setCreateAlertStatus("idle"));
  }, [createAlertStatus, dispatch])

  return (
    <LeftContainer maxWidth="md">
      <FHCard>
        <FHCardHeader
          title="Prepare Gameday Themes"
          subheader={getEnglishDateWithTime(new Date())}
        />
        <FHCardContent>
          <GamedayThemeForm onSubmit={handleCreate} isSuccess={createAlertStatus === "success"} />
        </FHCardContent>
      </FHCard>
    </LeftContainer>
  );
};

export default memo(GamedayTheme);
