// auth actions
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_USER_AUTH_STATUS = 'SET_USER_AUTH_STATUS';

// alert actions
export const SET_ALERTS = 'SET_ALERTS';
export const SET_LATEST_NOTIFICATION = 'SET_LATEST_NOTIFICATION';
export const SET_LATEST_SURVEY_NOTIFICATION = 'SET_LATEST_SURVEY_NOTIFICATION';
export const SET_LATEST_NEWS_NOTIFICATIONS = 'SET_LATEST_NEWS_NOTIFICATIONS';
export const SET_GAMEDAY_PRESETS = 'SET_GAMEDAY_PRESETS';
export const SET_GAME_ALERTS = 'SET_GAME_ALERTS';
export const SET_CREATE_ALERT_STATUS = 'SET_CREATE_ALERT_STATUS';
export const SET_SAVE_ALERT_STATUS = 'SET_SAVE_ALERT_STATUS';
export const ADD_ALERT = 'ADD_ALERT';
export const UPDATE_ALERT = 'UPDATE_ALERT';

// game actions
export const FETCH_GAMES = 'FETCH_GAMES';
export const SET_SELECTED_GAME = 'SET_SELECTED_GAME';
export const SET_CLOSEST_UPCOMING_GAME = 'SET_CLOSEST_UPCOMING_GAME';
export const SELECT_DATE = 'SELECT_DATE';
export const SELECT_GAME = 'SELECT_GAME';
export const SET_ALERT_TO_SHOW = 'SET_ALERT_TO_SHOW';

// auxiliary actions
export const SET_PATH_TOKENS = 'SET_PATH_TOKENS';
export const SET_LOADING_STATUS = 'SET_LOADING_STATUS';
export const SET_RESPONSE_SUCCESS = 'SET_RESPONSE_SUCCESS';
export const SET_RESPONSE_ERROR = 'SET_RESPONSE_ERROR';

// fanband actions
export const SET_FANBANDS = 'SET_FANBANDS';
export const SET_FANBANDS_STATISTICS = 'SET_FANBANDS_STATISTICS';

// user actions
export const SET_USERS = 'SET_USERS';

// main state actions
export const SET_TICKETS = 'SET_TICKETS';
export const SET_UPLOADED_TICKETS = 'SET_UPLOADED_TICKETS';
export const SET_TICKETS_UPLOADING_PROGRESS = 'SET_TICKETS_UPLOADING_PROGRESS';
