import { makeStyles } from '@material-ui/core';
import { getFanbandsStatistics, getAlerts } from 'redux/actions';
import clsx from 'clsx';
import {
  FHCard,
  FHCardContent,
  FHCardHeader,
} from 'components/styled-components';
import { AlertItem } from 'components/widgets';
import { Fragment, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ALERT_PROTO_TYPES, LINKS } from 'utils/constants';
import { useAsyncAction, usePagination } from 'hooks';
import { calcPercent } from 'utils/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
  },
  table: {
    tableLayout: 'fixed',
    width: '100%',
  },
  cell: {
    fontFamily: theme.custom.fonts.SFProDisplayBlackItalic,
    fontSize: 16,
    letterSpacing: '0.48px',
    color: 'black',
    textTransform: 'uppercase',
    textAlign: 'center',
    padding: theme.spacing(1, 0),
    overflowWrap: "break-word",
  },
  divider: {
    height: 0,
    borderBottom: '1px solid #9ea3ba',
  },
  color0: {
    color: '#53c76e',
  },
  color1: {
    color: '#d02231',
  },
  color2: {
    color: '#53c76e',
  },
}));

const QuickPollAlertsSent = ({ gamedayOnly }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const totalCount = useSelector(
    (state) => state.main.fanbands.statistics.total
  );
  const game = useSelector((state) => state.games.selectedGame);
  const { results: alerts, total } = useSelector((state) => state.notifications[ALERT_PROTO_TYPES.survey]);

  const surveys = useMemo(
    () =>
      alerts.map((alert) => {
        const resNum = alert.surveyResponses.slice(0, 5).reduce(
          (num, { count = 0 }) => num + count,
          0
        );

        return {
          ...alert,
          aggr: {
            ...alert.surveyResponses.reduce(
              (acc, { response: res, count = 0 }, idx) => ({
                ...acc,
                [`${res}-${idx}`]: {
                  count,
                  percent: calcPercent(count, resNum),
                },
              }),
              {}
            ),
            sent: {
              count: alert.sent,
              percent: calcPercent(alert.sent, totalCount),
            },
            open: {
              count: alert.opened,
              percent: calcPercent(alert.opened, totalCount),
            },
          },
        };
      }),
    [alerts, totalCount]
  );

  const maxResNum = useMemo(() => 
    Math.min(5, Math.max(...surveys.map((alert) => alert.surveyResponses.length)))
  , [surveys]);

  const getAlertsAction = useCallback((params) => {
    dispatch(getAlerts({ 
      ...params, 
      type: ALERT_PROTO_TYPES.survey,
      ...gamedayOnly && { gameId: game?.id },
    }));
  }, [dispatch, game?.id, gamedayOnly]);

  const { paginator } = usePagination({
    count: total,
    action: getAlertsAction,
  });

  useAsyncAction(getFanbandsStatistics(), !totalCount);

  return (
    <FHCard className={classes.root}>
      <FHCardHeader title="Quick Poll ALERTS SENT" />
      <FHCardContent>
        <table className={classes.table}>
          <thead>
            <tr>
              <th style={{ width: 200 }}></th>
              <th colSpan={maxResNum}></th>
              <th className={classes.cell}>sent</th>
              <th className={classes.cell}>open</th>
            </tr>
          </thead>
          <tbody>
            {surveys.map((alert, rowIdx) => (
              <Fragment key={alert.id}>
                <tr>
                  <td className={classes.cell}>
                    <AlertItem data={alert} href={LINKS.quickPollSample.path} />
                  </td>
                  {alert.surveyResponses.map(({ response: res }, idx) => (
                    <td
                      key={idx}
                      className={clsx(
                        classes.cell,
                        classes[`color${Math.min(idx, 2)}`]
                      )}
                    >
                      {res} <br />
                      {alert.aggr?.[`${res}-${idx}`].count} <br />
                      {alert.aggr?.[`${res}-${idx}`].percent}%
                    </td>
                  ))}
                  {Array.from({
                    length: maxResNum - alert.surveyResponses.length,
                  }).map((_, idx) => (
                    <td key={idx} className={classes.cell} />
                  ))}
                  <td className={classes.cell}>
                    {alert.aggr.sent.count} <br /> {alert.aggr.sent.percent}%
                  </td>
                  <td className={classes.cell}>
                    {alert.aggr.open.count} <br /> {alert.aggr.open.percent}%
                  </td>
                </tr>
                {rowIdx < surveys.length - 1 && (
                  <tr>
                    <td
                      className={classes.divider}
                      colSpan={maxResNum + 3}
                    ></td>
                  </tr>
                )}
              </Fragment>
            ))}
          </tbody>
        </table>
        {paginator}
      </FHCardContent>
    </FHCard>
  );
};

export default QuickPollAlertsSent;
