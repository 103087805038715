import { Layout, CurrentFanbandStats } from 'components/widgets';
import { TicketModify } from 'components/containers';
import { usePathIndicator } from 'hooks';
import { LINKS } from 'utils/constants';
import { useParams } from 'react-router-dom';

const ModifyTicketPage = () => {
  const { id } = useParams();

  usePathIndicator([LINKS.tickets, LINKS.ticketModify]);

  return (
    <Layout sidebar={<CurrentFanbandStats />}>
      <TicketModify ticketId={id} />
    </Layout>
  );
};

export default ModifyTicketPage;
