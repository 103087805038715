import { memo, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';

import { getGamedayPresets, getAlerts } from 'redux/actions';
import { Title } from 'components/elements';
import { AlertItem } from 'components/widgets';
import { Button } from '@material-ui/core';
import { useAsyncAction } from 'hooks';
import { ALERT_LABELS, ALERT_MIXED_TYPES, ALERT_STATUS, ALERT_STATUS_LABELS } from 'utils/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  items: {
    overflow: 'auto',
    height: 0,
    marginBottom: 16,
    flexGrow: 1,
  },
  button: {
    borderRadius: theme.spacing(3),
    height: 50,
    marginBottom: 5,
  },
}));

const AlertsSent = ({
  type,
  link = null,
  alertLink = null,
  status = ALERT_STATUS.sent,
  gamedayOnly = false,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const game = useSelector((state) => state.games.selectedGame);
  const alerts = useSelector((state) => state.notifications[type]?.results);

  const fileteredAlerts = useMemo(() => {
    if (type === ALERT_MIXED_TYPES.scheduled) {
      return alerts.filter(alert => 
        alert.isSent === (status !== ALERT_STATUS.pending)
      )
    }

    return alerts;
  }, [alerts, status, type]);

  const handleViewAll = useCallback(() => {
    navigate(link);
  }, [navigate, link]);

  const getAlertsAction = useMemo(() => {
    return getAlerts({ 
      ...gamedayOnly && { gameId: game?.id },
      type,
      take: 10,
    });
  }, [game?.id, gamedayOnly, type]);

  useAsyncAction(getAlertsAction, getAlertsAction);
  useAsyncAction(getGamedayPresets());

  return (
    <div className={classes.root}>
      <Title mb={4}>
        {ALERT_LABELS[type]} Alerts {ALERT_STATUS_LABELS[status]}
      </Title>

      <div className={classes.items}>
        {fileteredAlerts.map((item) => (
          <AlertItem key={item.id} data={item} mb={2} href={alertLink} />
        ))}
      </div>

      {link && (
        <Button
          color="primary"
          variant="contained"
          fullWidth
          className={classes.button}
          onClick={handleViewAll}
        >
          View all
        </Button>
      )}
    </div>
  );
};

export default memo(AlertsSent);
