import { createAction } from 'redux-actions';
import * as ActionTypes from 'redux/actionTypes';
import { readUsers } from 'services/api-users';
import { setResponseError } from '.';

export const setUsers = createAction(
  ActionTypes.SET_USERS,
  (payload) => payload
);

export const getUsers = (params) => async (dispatch) => {
  try {
    const fanbands = await readUsers(params);
    dispatch(setUsers(fanbands));
  } catch (e) {
    dispatch(setResponseError(e.response));
  }
};