import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setLoadingStatus } from 'redux/actions';

const useAsyncAction = (action, ...dependencies) => {
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (!action) {
        return;
      }
      
      dispatch(setLoadingStatus(true));
      await dispatch(action);
      dispatch(setLoadingStatus(false));
    })();
    // eslint-disable-next-line
  }, dependencies || []);
};

export default useAsyncAction;
