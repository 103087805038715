export const FANBAND_TYPES = Object.freeze({
  all: 'all',
  provisioned: 'provision',
  nonProvisioned: 'notProvision',
});

export const FANBAND_LABELS = Object.freeze({
  all: 'ALL',
  provisioned: 'PROVISIONED',
  nonProvisioned: 'NON-PROVISIONED',
});

export const ALERT_PROTO_TYPES = Object.freeze({
  news: 'news',
  survey: 'survey',
  score: 'scoreAlert',
  promo: 'promo',
  gameday: 'gameday',
});

export const ALERT_MIXED_TYPES = Object.freeze({
  all: 'all',
  scheduled: 'scheduled',
  saved: 'saved',
});

export const ALERT_LABELS = Object.freeze({
  [ALERT_PROTO_TYPES.news]: 'News',
  [ALERT_PROTO_TYPES.survey]: 'QuickPoll',
  [ALERT_PROTO_TYPES.score]: 'Score',
  [ALERT_PROTO_TYPES.promo]: 'Promo',
  [ALERT_PROTO_TYPES.gameday]: 'Gameday',
  [ALERT_MIXED_TYPES.scheduled]: 'Scheduled',
  [ALERT_MIXED_TYPES.saved]: 'Saved',
});

export const ANSWER_ENUM = Object.freeze({
  NO: 0,
  YES: 1,
  IGNORED: 2,
});

export const GAME_STATUS = Object.freeze({
  scheduled: 1,
  inProgress: 2,
  finished: 3,
});

export const WS_EVENTS = Object.freeze({
  FANBAND_IN_AREA: 'USER_IN_AREA',
  FANBAND_STATUS: 'USER_STATUS',
  FANBAND_REACT: 'USER_REACT',
  FANBAND_ANSWER: 'USER_ANSWER',
  UPCOMING_SCHEDULED_NOTIFICATION: 'UPCOMING_SCHEDULED_NOTIFICATION',
  LIVE_GAME_UPDATE: 'LIVE_GAME_UPDATE',
  SENT_SCHEDULED_NOTIFICATION: 'SENT_SCHEDULED_NOTIFICATION',
});

export const VIB_TYPES = Object.freeze({
  quickBursts: 'quickBursts',
  longVibrate: 'longVibrate',
  none: 'noVibration',
});

export const VIB_INTENSITIES = Object.freeze({
  none: 'noVibration',
  low: 'low',
  medium: 'medium',
  high: 'high',
});

export const LED_TYPES = Object.freeze({
  flashing: 'flashing',
  stable: 'stable',
  none: 'noLed',
});

export const PRESET_PATTERNS = [
  {
    // label: 'All Red',
    presetPatternIndex: 0,
    topColor1: 'rgb(255,0,0)',
    topColor2: 'rgb(255,0,0)',
    topColor3: 'rgb(255,0,0)',
    bottomColor1: 'rgb(255,0,0)',
    bottomColor2: 'rgb(255,0,0)',
    bottomColor3: 'rgb(255,0,0)',
    vibrationIntensity: VIB_INTENSITIES.high,
    vibrationType: VIB_TYPES.quickBursts,
    duration: 10,
    ledType: LED_TYPES.flashing,
  },
  {
    // label: 'All Green',
    presetPatternIndex: 1,
    topColor1: 'rgb(0,255,0)',
    topColor2: 'rgb(0,255,0)',
    topColor3: 'rgb(0,255,0)',
    bottomColor1: 'rgb(0,255,0)',
    bottomColor2: 'rgb(0,255,0)',
    bottomColor3: 'rgb(0,255,0)',
    vibrationIntensity: VIB_INTENSITIES.high,
    vibrationType: VIB_TYPES.quickBursts,
    duration: 10,
    ledType: LED_TYPES.flashing,
  },
  {
    // label: 'All Blue',
    presetPatternIndex: 2,
    topColor1: 'rgb(0,0,255)',
    topColor2: 'rgb(0,0,255)',
    topColor3: 'rgb(0,0,255)',
    bottomColor1: 'rgb(0,0,255)',
    bottomColor2: 'rgb(0,0,255)',
    bottomColor3: 'rgb(0,0,255)',
    vibrationIntensity: VIB_INTENSITIES.high,
    vibrationType: VIB_TYPES.quickBursts,
    duration: 10,
    ledType: LED_TYPES.flashing,
  },
  {
    // label: 'All Yellow',
    presetPatternIndex: 3,
    topColor1: 'rgb(255,233,0)',
    topColor2: 'rgb(255,233,0)',
    topColor3: 'rgb(255,233,0)',
    bottomColor1: 'rgb(255,233,0)',
    bottomColor2: 'rgb(255,233,0)',
    bottomColor3: 'rgb(255,233,0)',
    vibrationIntensity: VIB_INTENSITIES.high,
    vibrationType: VIB_TYPES.quickBursts,
    duration: 10,
    ledType: LED_TYPES.flashing,
  },
  {
    // label: 'All Orange',
    presetPatternIndex: 4,
    topColor1: 'rgb(255,164,0)',
    topColor2: 'rgb(255,164,0)',
    topColor3: 'rgb(255,164,0)',
    bottomColor1: 'rgb(255,164,0)',
    bottomColor2: 'rgb(255,164,0)',
    bottomColor3: 'rgb(255,164,0)',
    vibrationIntensity: VIB_INTENSITIES.high,
    vibrationType: VIB_TYPES.quickBursts,
    duration: 10,
    ledType: LED_TYPES.flashing,
  },
  {
    // label: 'All Purple',
    presetPatternIndex: 5,
    topColor1: 'rgb(172,79,198)',
    topColor2: 'rgb(172,79,198)',
    topColor3: 'rgb(172,79,198)',
    bottomColor1: 'rgb(172,79,198)',
    bottomColor2: 'rgb(172,79,198)',
    bottomColor3: 'rgb(172,79,198)',
    vibrationIntensity: VIB_INTENSITIES.high,
    vibrationType: VIB_TYPES.quickBursts,
    duration: 10,
    ledType: LED_TYPES.flashing,
  },
  {
    // label: 'Half Red/Blue',
    presetPatternIndex: 6,
    topColor1: 'rgb(255,0,0)',
    topColor2: 'rgb(255,0,0)',
    topColor3: 'rgb(255,0,0)',
    bottomColor1: 'rgb(0,0,255)',
    bottomColor2: 'rgb(0,0,255)',
    bottomColor3: 'rgb(0,0,255)',
    vibrationIntensity: VIB_INTENSITIES.high,
    vibrationType: VIB_TYPES.quickBursts,
    duration: 10,
    ledType: LED_TYPES.flashing,
  },
  {
    // label: 'Half Red/Green',
    presetPatternIndex: 7,
    topColor1: 'rgb(255,0,0)',
    topColor2: 'rgb(255,0,0)',
    topColor3: 'rgb(255,0,0)',
    bottomColor1: 'rgb(0,255,0)',
    bottomColor2: 'rgb(0,255,0)',
    bottomColor3: 'rgb(0,255,0)',
    vibrationIntensity: VIB_INTENSITIES.high,
    vibrationType: VIB_TYPES.quickBursts,
    duration: 10,
    ledType: LED_TYPES.flashing,
  },
  {
    // label: 'All American',
    presetPatternIndex: 8,
    topColor1: 'rgb(255,0,0)',
    topColor2: 'rgb(0,0,0)',
    topColor3: 'rgb(0,0,255)',
    bottomColor1: 'rgb(255,0,0)',
    bottomColor2: 'rgb(0,0,0)',
    bottomColor3: 'rgb(0,0,255)',
    vibrationIntensity: VIB_INTENSITIES.high,
    vibrationType: VIB_TYPES.quickBursts,
    duration: 10,
    ledType: LED_TYPES.flashing,
  },
  {
    // label: 'None',
    presetPatternIndex: 9,
    topColor1: 'rgb(0,0,0)',
    topColor2: 'rgb(0,0,0)',
    topColor3: 'rgb(0,0,0)',
    bottomColor1: 'rgb(0,0,0)',
    bottomColor2: 'rgb(0,0,0)',
    bottomColor3: 'rgb(0,0,0)',
    vibrationIntensity: VIB_INTENSITIES.none,
    vibrationType: VIB_TYPES.none,
    duration: 0,
    ledType: LED_TYPES.none,
  },
];

export const DEFAULT_ALERT_PARAMS = () => {
  if (typeof window !== 'undefined') {
    const localData = localStorage.getItem('default_alert_params');

    if (localData) {
      return JSON.parse(localData);
    }
  }

  return PRESET_PATTERNS[0];
};

export const ALERT_FORM_MODES = Object.freeze({
  creating: 'creating',
  updating: 'updating',
  saving: 'saving',
});

export const ALERT_STATUS = Object.freeze({
  pending: 'pending',
  sent: 'sent',
});

export const ALERT_STATUS_LABELS = Object.freeze({
  [ALERT_STATUS.pending]: 'Pending',
  [ALERT_STATUS.sent]: 'Sent',
});

export const MOBILE_OS = Object.freeze({
  android: 'android',
  iOS: 'iOS',
  windowsPhone: 'windowsPhone',
  unknown: 'unknown',
});
