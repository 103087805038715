import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';

import combinedReducer from 'redux/reducers';

const bindMiddleWares = (middleWares) => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension');
    return composeWithDevTools(applyMiddleware(...middleWares));
  }
  return applyMiddleware(...middleWares);
};

const store = createStore(combinedReducer, bindMiddleWares([thunkMiddleware]));

export default store;
