import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { getGamedayPresets, getAlerts } from 'redux/actions';
import { Title } from 'components/elements';
import { AlertItem } from 'components/widgets';
import { useAsyncAction } from 'hooks';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 0,
    flexGrow: 1,
    overflowY: 'scroll',
    '&::scrollbar': {
      width: '0.6em',
    },
    '&::scrollbar-track': {
      'box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      borderRadius: theme.spacing(0.5),
    },
  },
}));

const ActivityTimeline = () => {
  const classes = useStyles();
  const game = useSelector((state) => state.games.selectedGame);
  const alerts = useSelector((state) => state.notifications.all.results);

  const getAlertsAction = useMemo(() => {
    return getAlerts({ 
      gameId: game?.id,
      take: 100,
    });
  }, [game?.id]);

  useAsyncAction(getAlertsAction, getAlertsAction);
  useAsyncAction(getGamedayPresets());

  return (
    <div className={classes.root}>
      <Title mb={4}>Activity Timeline</Title>
      <div className={classes.container}>
        {alerts.map((item) => (
          <AlertItem key={item.id} data={item} mb={2} />
        ))}
      </div>
    </div>
  );
};

export default memo(ActivityTimeline);
