import * as TYPES from 'redux/actionTypes';
import moment from 'moment-timezone';
import _ from 'lodash';
import { ALERT_MIXED_TYPES, ALERT_PROTO_TYPES } from 'utils/constants';

const INITIAL_STATE = Object.freeze({
  ...Object.values(ALERT_PROTO_TYPES).reduce(
    (acc, type) => ({ ...acc, [type]: { results: [], total: 0 } }),
    {}
  ),
  ...Object.values(ALERT_MIXED_TYPES).reduce(
    (acc, type) => ({ ...acc, [type]: { results: [], total: 0 } }),
    {}
  ),
  latest: {},
  latestSurvey: {},
  latestNews: [],
  selectedDate: new Date(),
  alertStatus: {},
  alertsToShow: Object.values(ALERT_PROTO_TYPES).reduce(
    (acc, type) => ({ ...acc, [type]: true }),
    {}
  ),
  acc: {
    results: [],
    total: 0,
  },
  gamedayPresets: [],
  createAlertStatus: "idle",
  saveAlertStatus: "idle",
});

const alertsReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.SET_ALERTS:
      const { type, results, total } = payload;

      if (type) {
        return { ...state, [type]: payload };
      }

      const grouped = _.groupBy(
        results
          .filter((n) => n.isSent)
          .map((n) => ({
            ...n,
            date: moment(n.scheduledTime || n.createdAt).format('YYYY-MM-DD'),
          })),
        'date'
      );

      for (let date in grouped) {
        grouped[date] = grouped[date].reduce(
          (acc, n) => ({
            ...acc,
            [n.type]: true,
          }),
          {}
        );
      }

      return { ...state, all: { results, total }, alertStatus: grouped };
    case TYPES.SET_LATEST_NOTIFICATION:
      return { ...state, latest: payload };
    case TYPES.SET_LATEST_SURVEY_NOTIFICATION:
      return { ...state, latestSurvey: payload };
    case TYPES.SET_LATEST_NEWS_NOTIFICATIONS:
      return { ...state, latestNews: payload };
    case TYPES.SELECT_DATE:
      return { ...state, selectedDate: payload };
    case TYPES.SET_ALERT_TO_SHOW:
      const { alert, visibility } = payload;
      const { alertsToShow } = state;
      return {
        ...state,
        alertsToShow: { ...alertsToShow, [alert]: visibility },
      };
    case TYPES.SET_GAMEDAY_PRESETS:
      return {
        ...state,
        gamedayPresets: payload,
      };
    case TYPES.SET_CREATE_ALERT_STATUS:
      return { ...state, createAlertStatus: payload };
    case TYPES.SET_SAVE_ALERT_STATUS:
      return { ...state, saveAlertStatus: payload };
    case TYPES.ADD_ALERT: {
      const { alert, type } = payload;
      const alerts = state[type ?? "all"].results.slice();
      const index = alerts.findIndex(x => x.id === alert.id);
      if (index == -1) {
        alerts.push(alert);
      }
      return { 
        ...state, 
        [type ?? "all"]: { results: alerts, totol: alerts.length } 
      };
    }
    case TYPES.UPDATE_ALERT: {
      const { alert, type } = payload;
      const alerts = state[type ?? "all"].results.slice();
      const index = alerts.findIndex(x => x.id === alert.id);
      if (index > -1) {
        alerts[index] = {
          ...alerts[index],
          ...alert,
        }
      }
      return { 
        ...state, 
        [type ?? "all"]: { results: alerts, totol: alerts.length } 
      };
    }
  default:
      return state;
  }
};

export default alertsReducer;
