import { memo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createAlert, setCreateAlertStatus } from 'redux/actions';
import { getEnglishDateWithTime } from 'utils/helpers';
import { ALERT_PROTO_TYPES } from 'utils/constants';
import { PromoForm } from 'components/widgets';
import {
  FHCard,
  FHCardHeader,
  FHCardContent,
  LeftContainer,
} from 'components/styled-components';

const Promo = () => {
  const dispatch = useDispatch();
  const createAlertStatus = useSelector((state) => state.notifications.createAlertStatus);

  const handleCreate = async (data) => {
    dispatch(createAlert(ALERT_PROTO_TYPES.promo, data));
  };

  useEffect(() => {
    dispatch(setCreateAlertStatus("idle"));
  }, [createAlertStatus, dispatch])

  return (
    <LeftContainer maxWidth="md">
      <FHCard>
        <FHCardHeader
          title="Create Promo Alert"
          subheader={getEnglishDateWithTime(new Date())}
        />
        <FHCardContent>
          <PromoForm onSubmit={handleCreate} isSuccess={createAlertStatus === "success"} />
        </FHCardContent>
      </FHCard>
    </LeftContainer>
  );
};

export default memo(Promo);
