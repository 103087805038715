import React, { useMemo, useCallback } from 'react';
import { Box, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useAsyncAction } from 'hooks';
import { getUsers } from 'redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { formatPhone, isEmpty } from 'utils/helpers';

const PhonePicker = ({
  error,
  label,
  value,
  placeholder,
  onChange,
  fullWidth,
  ...boxProps
}) => {
  const dispatch = useDispatch();
  const { results: users, total } = useSelector((state) => state.main.users);

  const phones = useMemo(() => {
    return users.map((f) => formatPhone(f.phone))
  }, [users]);

  const getUsersAction = useCallback((params) => {
    if (!total || users.length < total) {
      dispatch(getUsers({ ...params, take: total ? total : 50 }));
    }
  }, [dispatch, users.length, total]);

  useAsyncAction(getUsersAction, getUsersAction);

  return (
    <Box {...boxProps}>
      <Autocomplete
        multiple
        fullWidth={fullWidth}
        options={phones}
        value={value}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            helperText={error}
            error={!!error}
            variant="standard"
            label={label}
            placeholder={isEmpty(value) ? placeholder : ''}
            InputLabelProps={{ shrink: true }}
          />
        )}
      />
    </Box>
  );
};

export default PhonePicker;
