import React, { useState, useCallback, useEffect } from 'react';
import {
  TextField,
  InputAdornment,
  Popover,
  MenuList,
  MenuItem,
  makeStyles,
  InputBase,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { usePagination } from 'hooks';
import { getUsers } from 'redux/actions';
import { useSelector, useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  item: {
    fontFamily: theme.custom.fonts.SFProTextBold,
    fontSize: 14,
    paddingLeft: 0,
    color: '#000',
    '&>span': {
      fontFamily: theme.custom.fonts.SFProTextRegular,
    },
  },
  hidden: {
    display: 'none',
  },
  tabPanel: {
    paddingTop: 0,
  },
  menuContainer: {
    padding: 10,
    paddingBottom: 0,
  }
}));

const UserSelector = React.forwardRef(
  ({ error, label, value, phone, ...rest }, ref) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const popoverActions = React.useRef();
    const [anchorEl, setAnchorEl] = useState(null);
    const [phoneLabel, setPhoneLabel] = useState(phone ? `Phone: ${phone || ''}` : undefined);

    const { results: users, total } = useSelector((state) => state.main.users);
    
    // update popover position when content size changes
    useEffect(() => {
      popoverActions.current?.updatePosition();
    }, [users.length])

    const handleClick = (user) => {
      const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        'value'
      ).set;
      nativeInputValueSetter.call(ref.current, user.id);
      setPhoneLabel(`Phone: ${user.phone || ''}`);
      const ev1 = new Event('input', { bubbles: true });
      ref.current.dispatchEvent(ev1);
      setAnchorEl(null);
    };

    const [search, setSearch] = useState("");
  
    const getUsersAction = useCallback((params) => {
      dispatch(getUsers({ ...params, phoneNumber: search }));
    }, [dispatch, search]);
    
    const { paginator } = usePagination({
      count: total,
      action: getUsersAction,
    });

    return (
      <React.Fragment>
        <TextField
          label={label}
          helperText={error}
          error={!!error}
          value={value && phoneLabel}
          placeholder="Not assigned"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <KeyboardArrowDownIcon />
              </InputAdornment>
            ),
            readOnly: true,
          }}
          InputLabelProps={{ shrink: true }}
          onClick={(e) => setAnchorEl(e.target)}
        />
        <TextField
          inputRef={ref}
          {...rest}
          value={value}
          className={classes.hidden}
        />
        <Popover
          open={Boolean(anchorEl)}
          action={popoverActions}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <div className={classes.menuContainer}>
            <InputBase
              placeholder="Search by phone number"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            /> 
            <MenuList>
              {users.map((user) => {
                const { id, phone } = user;
                return (
                  <MenuItem
                    key={id}
                    className={classes.item}
                    onClick={() => handleClick(user)}
                    selected={id === value}
                  >
                    <span>&nbsp;{` ∙ Phone: ${phone}`}</span>
                  </MenuItem>
                );
              })}
            </MenuList>
          </div>
          {paginator}
        </Popover>
      </React.Fragment>
    );
  }
);

export default UserSelector;
