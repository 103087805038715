import { Layout } from 'components/widgets';
import { Fanbands } from 'components/containers';
import { ActivityTimeline } from 'components/widgets';
import { usePathIndicator } from 'hooks';
import { LINKS } from 'utils/constants';

export default function FanbandsPage() {
  usePathIndicator([LINKS.stats, LINKS.statsFanbands]);

  return (
    <Layout sidebar={<ActivityTimeline />}>
      <Fanbands />
    </Layout>
  );
}
